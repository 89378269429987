import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import React from 'react'
import {createRoot} from 'react-dom/client'
import apiConfig from './api-client/apiConfig'
import LoadingText from './components/LoadingText'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import {ExtraErrorData, HttpClient} from '@sentry/integrations'
import './styles/LoadingText.css'

Sentry.init({
	dsn:
		import.meta.env.VITE_ENVIRONMENT == 'local' ?
			''
		:	import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserProfilingIntegration(),
		new Sentry.BrowserTracing(),
		new ExtraErrorData({depth: 10}),
		new HttpClient(),
	],
	environment: import.meta.env.VITE_ENVIRONMENT,
	// For finer control of sent transactions you can adjust this value, or
	// use tracesSampler
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/vahterus-sales-cloud-(production|staging|develop|preview)-backend.trafficmanager.net/,
	],
	sendDefaultPii: true,
	release: `crm-webapp-${
		import.meta.env.VITE_ENVIRONMENT
	}-${__PACKAGE_JSON_VERSION__}`,
})

posthog.init(
	import.meta.env.VITE_ENVIRONMENT == 'local' ?
		''
	:	'phc_alra384S2CIKjL3eHdqpefqcxhY5GJCwP4scIwvz2M4',
	{
		api_host: 'https://eu.posthog.com',
	},
)

const App = React.lazy(() => import('./App'))
const Auth0Provider = React.lazy(() => import('./lazyloadLibs/Auth0Provider'))

export const queryClient = new QueryClient()
const Main: React.FC = () => {
	return (
		<React.StrictMode>
			<React.Suspense fallback={<LoadingText />}>
				<Auth0Provider
					domain={apiConfig.domain}
					clientId={apiConfig.client_id}
					authorizationParams={{
						redirect_uri: `${window.location.origin}/callback`,
						audience: apiConfig.audience,
						scope: apiConfig.scope,
					}}
					cacheLocation="localstorage"
				>
					<QueryClientProvider client={queryClient}>
						<App />
						{import.meta.env.VITE_ENVIRONMENT === 'local' && (
							<ReactQueryDevtools />
						)}
					</QueryClientProvider>
				</Auth0Provider>
			</React.Suspense>
		</React.StrictMode>
	)
}

const app = document.querySelector('#app')
if (app) createRoot(app).render(<Main />)
